import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="👋" keywords={['software engineer', 'frontend', 'react', 'javascript']} />
    <hr/>
    <p>
      Hi! I'm Ricardo Soares, a professional software engineer specialized in JavaScript and I translate human processes to computer instructions, often in the form of useful UIs. I'm passionate about "code archeology" and I love exploring how to build interfaces based out of huge datasets (a.k.a. data-viz). I also co-founded <a href="https://www.facebook.com/GeekSessionsFaro/" target="_blank" rel="noopener noreferrer">Geek Sessions FAO</a>, a local meetup series that aims to bring the local tech community togheter.
    </p>
  </Layout>
)

export default IndexPage
